import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './App.css';

const root = ReactDOM.createRoot(
  (
    document.getElementById('actual-shadow')?.shadowRoot?.getElementById('actual') 
    ?? document.getElementById('actual')
  ) as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);