import { useState } from 'react';
import styles from './actual.module.scss'
import ActualModal from "./modal";
import clsx from "clsx";
import { iActual } from "../../iActual";

type Props = {
    item: iActual
    index: number,
    showBtn: boolean
}

export function ActualItem({ item, index, showBtn }: Props) {
    const [showModal, setShowModal] = useState(false)
    if (!item.image?.length) return null
    return (
        <>
            <div className={styles.item_actual__content} onClick={() => setShowModal(true)}>
                {showBtn && !!item.button_title.length && !!item.button_link.length &&
                    <a href={item.button_link} className={clsx(styles.item_actual__btn, "btn")}>{item.button_title}</a>
                }
            </div>
            <div className={styles.item_actual__img}>
                <img src={item.image} alt="" width={313} height={556} />
            </div>
            <ActualModal startSlide={index} opened={showModal} onClose={() => setShowModal(false)} />
        </>
    )
}