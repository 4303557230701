import { mergeRefs } from "./mergeRefs";
import React, { InputHTMLAttributes, useEffect, useRef } from "react"

const InputPhone = React.forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
    const localRef = useRef<HTMLInputElement>();
    useEffect(() => {
        if (localRef && localRef.current) {
            localRef.current.addEventListener('keydown', onPhoneKeyDown);
            localRef.current.addEventListener('input', onPhoneInput, false);
            localRef.current.addEventListener('paste', onPhonePaste, false);

            const cleanupCopyRef = localRef.current
            return () => {
                cleanupCopyRef?.addEventListener('keydown', onPhoneKeyDown);
                cleanupCopyRef?.addEventListener('input', onPhoneInput, false);
                cleanupCopyRef?.addEventListener('paste', onPhonePaste, false);
            }
        }
    }, [])

    return <input ref={mergeRefs([localRef, ref])} {...props} />;
});

const onPhoneKeyDown = function (e: KeyboardEvent) {
    // Clear input after remove last symbol
    const inputValue = (e.target as HTMLInputElement).value.replace(/\D/g, '');
    if (e.keyCode == 8 && inputValue.length == 1) {
        (e.target as HTMLInputElement).value = "";
    }
}

const getInputNumbersValue = function (input: HTMLInputElement) {
    // Return stripped input value — just numbers
    return input.value.replace(/\D/g, '');
}

const onPhoneInput = function (e: any) {
    let input = (e.target as HTMLInputElement),
        inputNumbersValue = getInputNumbersValue(input),
        selectionStart = input.selectionStart,
        formattedInputValue = "";

    if (!inputNumbersValue) {
        return input.value = "";
    }

    if (input.value.length != selectionStart) {
        // Editing in the middle of input, not last symbol
        if (e.data && /\D/g.test(e.data)) {
            // Attempt to input non-numeric symbol
            input.value = inputNumbersValue;
        }
        return;
    }

    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        if (inputNumbersValue[0] == "9") inputNumbersValue = "7" + inputNumbersValue;
        const firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
        formattedInputValue = input.value = firstSymbols + " ";
        if (inputNumbersValue.length > 1) {
            formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
        }
        if (inputNumbersValue.length >= 5) {
            formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
        }
        if (inputNumbersValue.length >= 8) {
            formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
        }
        if (inputNumbersValue.length >= 10) {
            formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
        }
    } else {
        formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
    }
    input.value = formattedInputValue;
}

const onPhonePaste = function (e: ClipboardEvent) {
    const input = (e.target as HTMLInputElement),
        inputNumbersValue = getInputNumbersValue(input);
    const pasted = e.clipboardData || (window as any).clipboardData;
    if (pasted) {
        const pastedText = pasted.getData('Text');
        if (/\D/g.test(pastedText)) {
            // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
            // formatting will be in onPhoneInput handler
            input.value = inputNumbersValue;
            return;
        }
    }
}

InputPhone.displayName = 'InputPhone';
export default InputPhone



