import SwiperOrig, { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './modal.module.scss'
import clsx from "clsx";
import { iActual } from "../../iActual";
import { ActualModalContentItemContent } from "./modalContentItemContent";

type Props = {
    item: iActual,
    storeInstance: (swiper: SwiperOrig) => void,
    mainSliderNext: () => void,
    isActive: boolean

    isMuted: boolean
    setIsMuted: Dispatch<SetStateAction<boolean>>
}

export function ActualModalContentSlide({ item, storeInstance, mainSliderNext, isActive, isMuted, setIsMuted }: Props) {
    // store swiper instance
    const [innerSwiper, setInnerSwiper] = useState<SwiperOrig | null>(null);
    useEffect(() => {
        if (innerSwiper) storeInstance(innerSwiper)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerSwiper])


    //progress
    const [showForm, setShowForm] = useState(false)
    const sliderProgress = useRef<HTMLDivElement>(null)
    const [slideChange, setSlideChange] = useState<number>(0)
    useEffect(() => {
        if (!innerSwiper || !sliderProgress.current) return
        //stop all video
        for (const slide of innerSwiper.slides) {
            const video = slide.querySelector("video") as HTMLVideoElement | null
            if (video) {
                video.pause()
                video.currentTime = 0
            }
        }
        if (showForm) return
        //progress bar
        let interval: NodeJS.Timer | null = null
        sliderProgress.current.querySelectorAll("div").forEach((el, index) => {
            const span = el.querySelector('span')
            if (!span) return
            if (index < innerSwiper.realIndex) {
                span.style.width = '100%';
            } else if (index > innerSwiper.realIndex) {
                span.style.width = '0%';
            } else {
                span.style.width = '0%';
                const handleSlideChange = async () => {
                    const video = innerSwiper.slides[innerSwiper.realIndex]?.querySelector("video") as HTMLVideoElement | null
                    let timeSeconds = 5
                    if (video) {
                        await video.play()
                        video.currentTime = 0
                        timeSeconds = video.duration
                    }
                    let progressPercent = 0
                    interval = setInterval(() => {
                        progressPercent += 100 / timeSeconds * 0.05
                        if (progressPercent >= 100) {
                            if (innerSwiper.realIndex + 1 >= innerSwiper.slides.length) {
                                mainSliderNext()
                            } else {
                                innerSwiper?.slideNext()
                                progressPercent = 0
                            }
                        }
                        span.style.width = progressPercent + '%'
                    }, 50)
                }
                if (isActive) handleSlideChange()
            }
        })
        return () => { if (interval) clearInterval(interval) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerSwiper, slideChange, isActive, showForm])

    return <>
        <div className={clsx(styles.actual_progress, {
            [styles.actual_progress_hide]: showForm
        }
        )} ref={sliderProgress}>
            {item.images.map((img, index) =>
                <div key={index} className={styles.actual_progress__line}><span></span></div>
            )}
            {item.video.map((video, index) =>
                <div key={index} className={styles.actual_progress__line}><span></span></div>
            )}
        </div>
        <ul className={styles.action}>
            {slideChange > (item.images.length - 1) &&
                <li>
                    <div className={clsx(styles.btn_action, {
                        ['btn_action_sound_active']: isActive
                    })} onClick={() => setIsMuted(muted => !muted)}>
                        <span className="btn_action__icon">
                            <svg className={styles.icon_sound}>
                                <use xlinkHref={"/actial_icons.svg#sound" + (!isMuted ? "-active" : "")}></use>
                            </svg>
                        </span>
                    </div>
                </li>
            }
        </ul>
        <Swiper
            className={clsx(styles.actual_slider, styles.inner_slider)}
            effect="fade"
            fadeEffect={{ crossFade: true }}
            modules={[EffectFade]}
            onSlideChange={(swiper) => {
                setShowForm(false)
                setSlideChange(swiper.realIndex)
            }}
            onSwiper={setInnerSwiper}
        >
            {item.images.map((img, index) =>
                <SwiperSlide key={index} className={styles.item_actual}>
                    <ActualModalContentItemContent
                        hasForm={item.form}
                        showForm={showForm}
                        setShowForm={setShowForm}
                        button_title={item.button_title}
                        button_link={item.button_link}
                    />
                    <div className={styles.item_actual__img}>
                        <img src={img} alt="" width={313} height={556} />
                    </div>
                </SwiperSlide>
            )}
            {item.video.map((video, index) =>
                <SwiperSlide key={index} className={styles.item_actual}>
                    <ActualModalContentItemContent
                        hasForm={item.form}
                        showForm={showForm}
                        setShowForm={setShowForm}
                        button_title={item.button_title}
                        button_link={item.button_link}
                    />
                    <div className={styles.item_actual__img}>
                        <video width={313} height={556} preload="none" muted={isMuted} playsInline={true}>
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                </SwiperSlide>
            )}
        </Swiper>
    </>
}