import SwiperOrig from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useState } from 'react';
import styles from './modal.module.scss'
import clsx from "clsx";
import useSWR from "swr";
import { ActualModalContentSlide } from "./modalContentSlide";
import { iActual } from "../../iActual";

type Props = {
    startSlide: number
    opened: boolean,
    onClose: () => void,
}

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export function ActualModalContent({ startSlide, opened, onClose }: Props) {
    // store swiper instance
    const [mainSwiper, setMainSwiper] = useState<SwiperOrig | null>(null);
    const [mainSlide, setMainSlide] = useState<number>(startSlide);
    const [innerSwiperInstance, setInnerSwiperInstance] = useState<{ [key: number]: SwiperOrig }>({});

    const onNavClick = (isNext: boolean) => {
        if (!mainSwiper) return
        const innerSwiper = innerSwiperInstance[mainSwiper.realIndex]
        const innerNewIndex = innerSwiper.realIndex + (isNext ? 1 : -1)
        if (innerNewIndex === -1) {
            if (mainSwiper.realIndex === 0) {
                onClose()
                return
            }
            mainSwiper.slideTo(mainSwiper.realIndex - 1)
        } else if (innerNewIndex + 1 > innerSwiper.slides.length) {
            if (mainSwiper.realIndex + 1 === mainSwiper.slides.length) {//TODO: баг нужно 2 клика
                onClose()
                return
            }
            mainSwiper.slideTo(mainSwiper.realIndex + 1)
        } else {
            innerSwiper.slideTo(innerNewIndex)
        }
    }

    const storeInnerSwiperInstance = (index: number, swiper: SwiperOrig) => {
        setInnerSwiperInstance(prev => ({ ...prev, [index]: swiper }))
    }

    const [isMuted, setIsMuted] = useState(true)

    const { data, error } = useSWR<iActual[]>('/ajax/actual', fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    if (error || !data) return null;
    // const data: iActual[] = [
    //     {
    //         id: 1,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: true,
    //     },
    //     {
    //         id: 2,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 3,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 4,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 5,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 6,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    // ]

    return (

        <div className={clsx(
            styles.fixed,
            styles.enterActive,
            { [styles.exitActive]: !opened }
        )}>
            <div className={styles.dialog}>
                <div className={styles.close} onClick={onClose}>
                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path id="path1" fill="#ffffff" fillRule="evenodd" stroke="none" opacity="0.7"
                            d="M 0.4249 17.523001 C -0.141701 18.0895 -0.141701 19.008101 0.4249 19.574699 C 0.991501 20.1413 1.910101 20.1413 2.476601 19.574699 L 9.999901 12.051399 L 17.523201 19.574699 C 18.089802 20.1413 19.0084 20.1413 19.575001 19.574699 C 20.141499 19.008101 20.141499 18.0895 19.575001 17.523001 L 12.051701 9.999699 L 19.5746 2.476738 C 20.141201 1.91016 20.141201 0.991562 19.5746 0.42498 C 19.007999 -0.141602 18.089401 -0.141602 17.5229 0.42498 L 9.999901 7.947899 L 2.476999 0.42498 C 1.9104 -0.141602 0.9918 -0.141602 0.4252 0.42498 C -0.1413 0.991562 -0.1413 1.91016 0.4252 2.476738 L 7.9482 9.999699 L 0.4249 17.523001 Z" />
                    </svg>
                </div>
                <div className={styles.content}>
                    <div className={styles.actual}>
                        <div className={styles.actual_slider_wrap}>
                            <Swiper
                                className={clsx(styles.actual_slider, styles.main_slider)}
                                initialSlide={startSlide}
                                onSlideChange={(swiper) => setMainSlide(swiper.realIndex)}
                                onSwiper={setMainSwiper}
                            //slideActiveClass={styles.main_slider_slide_active}
                            >
                                {data.map((item, index) =>
                                    <SwiperSlide key={item.id} className={styles.main_slider_slide}>
                                        <ActualModalContentSlide
                                            item={item}
                                            storeInstance={(swiper) => storeInnerSwiperInstance(index, swiper)}
                                            mainSliderNext={() => onNavClick(true)}
                                            isActive={mainSlide === index}
                                            isMuted={isMuted}
                                            setIsMuted={setIsMuted}
                                        />
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            <div className={styles.actual_slider_prev} onClick={() => onNavClick(false)}>
                                <svg className={styles.icon_arrow_prev}>
                                    <use xlinkHref="/actial_icons.svg#svg-arrow"></use>
                                </svg>
                            </div>
                            <div className={styles.actual_slider_next} onClick={() => onNavClick(true)}>
                                <svg className={styles.icon_arrow_next}>
                                    <use xlinkHref="/actial_icons.svg#svg-arrow"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}