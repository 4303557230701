import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

type Props = {
  children: ReactNode
}

const Portal = ({ children }: Props) => {
  const [container] = useState(() => document.createElement("div"));

  useEffect(() => {
    ((
      document.getElementById('actual-shadow')?.shadowRoot?.getElementById('actual')
      ?? document.getElementById('actual')
    ) as HTMLElement).appendChild(container);
    return () => {
      ((
        document.getElementById('actual-shadow')?.shadowRoot?.getElementById('actual')
        ?? document.getElementById('actual')
      ) as HTMLElement).removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
};

export default Portal;