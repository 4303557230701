import { Navigation, Pagination, } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useRef, useState } from 'react';
import styles from './actual.module.scss'
import { ActualItem } from "./item";
import useSWR from "swr";
import { iActual } from "../../iActual";

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export default function Actual() {
    //Add a state that will force a re-render
    const [_, setInit] = useState(false);
    const prevRef = useRef<HTMLDivElement>(null)
    const nextRef = useRef<HTMLDivElement>(null)
    const pagination = useRef<HTMLDivElement>(null)

    const { data, error } = useSWR<iActual[]>('/ajax/actual', fetcher, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    if (error || !data) return null;
    // const data: iActual[] = [
    //     {
    //         id: 1,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: true,
    //     },
    //     {
    //         id: 2,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 3,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 4,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 5,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    //     {
    //         id: 6,
    //         button_title: "Условия",
    //         button_link: "/",
    //         image: "/item_actual.jpg",
    //         images: ["/item_actual.jpg", "/item_actual.jpg", "/item_actual.jpg"],
    //         video: ["/video_about_company.mp4", "/video_about_company.mp4", "/video_about_company.mp4"],
    //         form: false,
    //     },
    // ]

    return (
        <>
            <section className={styles.actual}>
                {/* <SwiperNav prevRef={prevRef} nextRef={nextRef} pagination={pagination} /> */}
                <Swiper
                    className={styles.actual__slider}
                    modules={[Navigation, Pagination]}
                    watchOverflow={true}
                    pagination={{
                        el: pagination.current,
                        type: "fraction"
                    }}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView: 'auto',
                            spaceBetween: 0,
                        },
                        1280: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                    }}
                    onInit={() => setInit(true)}
                >
                    {data.map((item, index) =>
                        <SwiperSlide key={item.id} className={styles.item_actual}>
                            <ActualItem item={item} index={index} showBtn={false} />
                        </SwiperSlide>
                    )}
                </Swiper>
            </section>
        </>
    )
}