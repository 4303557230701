import { Dispatch, SetStateAction, useState } from 'react';
import styles from './modal.module.scss'
import clsx from "clsx";
import InputPhone from "../inputPhone";

type Props = {
    hasForm: boolean,
    showForm: boolean,
    setShowForm: Dispatch<SetStateAction<boolean>>
    button_title: string,
    button_link: string,
}

export function ActualModalContentItemContent({ hasForm, showForm, setShowForm, button_title, button_link }: Props) {
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)

    const onSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            name: { value: string };
            phone: { value: string };
            agree: { checked: boolean };
        };
        try {
            setSending(true)
            const body = new FormData();
            body.append('name', target.name.value);
            body.append('phone', target.phone.value);
            body.append('agree',  target.agree.checked ? "1" : "");
            const result = await (fetch('/ajax/actual_form', { method: "POST", body }).then((res) => res.json()) as Promise<{ success: boolean, error?: string }>)
            if (result.success) {
                setSuccess(true)
            } else {
                setError(result.error || "Ошибка")
            }
        } catch (error) {
            if (error instanceof Error) {
                setError("Ошибка отправки " + error.message)
            }
        } finally {
            setSending(false)
        }
    }
    if (showForm) return <div className={styles.item_actual__form}>
        <div className={styles.item_actual__form_close} onClick={() => setShowForm(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <circle cx="20" cy="20" r="20" fill="#F5F6F7" />
                <path opacity="0.32" fillRule="evenodd" clipRule="evenodd" d="M14.5808 24.2579C14.2602 24.5785 14.2602 25.0984 14.5808 25.4191C14.9015 25.7398 15.4214 25.7398 15.7421 25.4191L20.0002 21.161L24.2583 25.4191C24.579 25.7398 25.0989 25.7398 25.4196 25.4191C25.7402 25.0984 25.7402 24.5785 25.4196 24.2579L21.1615 19.9998L25.4194 15.7419C25.74 15.4212 25.74 14.9013 25.4194 14.5806C25.0987 14.2599 24.5788 14.2599 24.2581 14.5806L20.0002 18.8385L15.7423 14.5806C15.4216 14.2599 14.9017 14.2599 14.581 14.5806C14.2604 14.9013 14.2604 15.4212 14.581 15.7419L18.8389 19.9998L14.5808 24.2579Z" fill="#303538" />
            </svg>
        </div>
        <div className={styles.item_actual__form_title}>Отправить<br />заявку</div>
        {!success && <form onSubmit={onSubmit}>
            {!!error.length && <div className={styles.item_actual__form_error}>{error}</div>}
            <input name="name" type="text" placeholder="Ваше имя" />
            <InputPhone name="phone" type="text" placeholder="Ваш телефон" className="phone" />
            <input type="submit" value="Отправить" disabled={sending} />
            <input name="agree" type="checkbox" value="1" id="agree" />
            <label htmlFor="agree">
                <div>Я принимаю соглашения об <a href="/policy" target="_blank">обработке персональных данных</a></div>
            </label>
        </form>}
        {success && <div className={styles.item_actual__form_success}>Ваша заявка отправлена. Наш менеджер свяжется с вами.</div>}
    </div>

    return <div className={styles.item_actual__content}>
        {hasForm && !!button_title.length &&
            <a href="#" className={clsx(styles.item_actual__btn, "btn")} onClick={() => setShowForm(true)}>
                {button_title}
            </a>
        }
        {!hasForm && !!button_title.length && !!button_link.length &&
            <a href={button_link} className={clsx(styles.item_actual__btn, "btn")}>
                {button_title}
            </a>
        }
    </div>
}