import { useEffect, useState } from 'react';
import Portal from "../portal";
import { ActualModalContent } from "./modalContent";

type Props = {
    startSlide: number
    opened: boolean,
    onClose: () => void,
}

export default function ActualModal({ startSlide, opened, onClose }: Props) {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (opened && !mounted) {
            setMounted(true);
            document.body.classList.add("lock");
        } else if (!opened && mounted) {
            setTimeout(() => {
                setMounted(false);
                document.body.classList.remove("lock");
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opened]);

    if (!mounted) {
        return null;
    }

    return (
        <Portal>
            <ActualModalContent startSlide={startSlide} opened={opened} onClose={onClose} />
        </Portal>
    );
}