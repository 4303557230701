import Actual from "./components/actual";


function App() {
  return (
    <Actual/>
  );
}

export default App;
